<template>
  <div style="height: 100%">
    <v-toolbar flat dense>
      <v-chip
        :key="dateKey"
        small
        label
        outlined
        class="primaryText--text mx-1 smallText"
        @click="changeDateType()"
      >
        <v-icon x-small left>event</v-icon> Date Type:
        {{ params.dateType }}
      </v-chip>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-chip
            small
            label
            outlined
            class="primaryText--text mx-1 smallText"
            v-on="on"
          >
            <v-icon x-small left>event</v-icon>
            {{
              quickSelectDates.find((x) => x.value == params.selectedDate)
                ? quickSelectDates.find((x) => x.value == params.selectedDate)
                    .text
                : "Select Date"
            }}<v-icon small right>expand_more</v-icon>
          </v-chip>
        </template>
        <v-list style="overflow: hidden" dense>
          <v-list-item
            v-for="(date, index) in quickSelectDates"
            :key="index"
            @click="selectDate(date.value)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ date.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        v-if="params.selectedDate == 'select_dates'"
        ref="menu"
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            color="primary"
            class="mx-1"
            outlined
            label
            v-bind="attrs"
            v-on="on"
            small
            ><v-icon x-small left>date_range</v-icon>{{ dateText }}
            <v-icon small right>expand_more</v-icon></v-chip
          >
        </template>
        <v-date-picker
          v-model="params.dates"
          range
          color="primary"
          show-week
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary"> Cancel </v-btn>
          <v-btn text color="primary" @click="setDateFilter"> OK </v-btn>
        </v-date-picker>
      </v-menu>

      <v-spacer></v-spacer>
      <span class="mr-1 text" style="font-size: 12px"><b>{{ currentWeek ?? '' }}</b></span>

      <v-chip class="mr-1 searchChipWrap" style="max-width: 400px">
        <v-icon small color="grey" class="ml-1">search</v-icon>
        <v-text-field
          placeholder="Search"
          class="mb-1 searchInput"
          hide-details
          rounded
          clearable
          dense
          style="margin-top: 2px"
          v-model="search"
        ></v-text-field>

        <v-tooltip top v-if="filterActive">
          <template v-slot:activator="{ on }">
            <v-btn
              rounded
              right
              small
              icon
              color="redPop"
              @click="resetFilters"
              v-on="on"
            >
              <v-icon small>filter_alt_off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Clear Filters</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon right small @click="getContainers" v-on="on">
              <v-icon small>autorenew</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Refresh Containers</span>
        </v-tooltip>
      </v-chip>
    </v-toolbar>
    <ag-grid-vue
      groupDisplayType="multipleColumns"
      :animateRows="false"
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{
        type: 'fitCellContents',
        columnLimits: [],
      }"
      style="width: 100%; height: 85vh"
      :enableRangeSelection="true"
      class="ag-grid-theme-builder"
      :allowContextMenuWithControlKey="true"
      :columnDefs="columns"
      :rowData="containers"
      :suppressAggFuncInHeader="true"
      :defaultColDef="defaultColDef.def"
      :sideBar="sidebar"
      :statusBar="statusBar"
      :getRowId="getRowId"
      :getContextMenuItems="getContextMenuItems"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import Boolean from "../GridComponents/Boolean.vue";
import BookingState from "../BookingTables/BookingComponents/BookingState.vue";
import ContainerStatus from "../GridComponents/ContainerStatus.vue";
import BookingType from "../BookingTables/BookingComponents/BookingType.vue";
import Features from "../GridComponents/ContainerFeatures.vue";
import FileStatus from "../BookingTables/ContainerComponents/FileStatus.vue";
import dateFormat from "dateformat";
import * as moment from "moment";
import MovementType from "../BookingTables/BookingComponents/MovementType.vue";
import OrganisationName from "../GridComponents/Organisation.vue";
import PortCell from "../GridComponents/Port.vue";
import ContainerStockStatus from "../GridComponents/ContainerStockStatus.vue";
import TableHeader from "../GridComponents/TableHeader.vue";
import TemporaryProducts from "../GridComponents/TemporaryProducts.vue";
import TransportStatus from "../GridComponents/TransportStatus.vue";
// import Templates from "../AgGrid/GridSideBar/Templates.vue";
import UserCell from "../GridComponents/User.vue";
// import ViewContainer from "../ManageBooking/ContentComponents/ViewContainer.vue";
export default {
  components: {
    AgGridVue,
    Boolean,
    BookingState,
    BookingType,
    ContainerStatus,
    ContainerStockStatus,
    Features,
    FileStatus,
    MovementType,
    OrganisationName,
    PortCell,
    agColumnHeader: TableHeader,
    TemporaryProducts,
    TransportStatus,
    UserCell,
    // Templates,
  },
  data: () => ({
    columns: [],
    containers: null,
    containersQuery: null,
    containerTimeout: null,
    dateMenu: false,
    dateKey: 0,
    defaultColDef: {
      def: {
        sortable: true,
        resizable: true,
        filter: true,
        enableRowGroup: true,
        enablePivot: true,
        flex: 1,
        minWidth: 100,
        cellClass: "d-flex align-center",
        cellStyle: { textAlign: "center", fontSize: "11px" },
      },
    },
    filterActive: false,
    filterSetting: {},
    gridApi: null,
    initialState: {},
    loading: false,
    params: {
      dateType: "ETD",
    },
    quickSelectDates: [
      {
        text: "Specify Dates",
        type: "current",
        value: "select_dates",
        difference: 0,
      },
      // {
      //     text: 'Past 30 days',
      //     type: 'past',
      //     value: 'past_month',
      //     difference: -1
      // },
      {
        text: "Past 7 Days",
        value: "past_week",
        type: "past",
        difference: -7,
      },
      {
        text: "This week",
        type: "current",
        value: "current_week",
        difference: 0,
      },
      {
        text: "Next 2 weeks",
        type: "future",
        value: "2_week",
        difference: 0,
      },
      {
        text: "Next 6 weeks",
        type: "future",
        value: "6_week",
        difference: 0,
      },
    ],
    search: null,
    sidebar: {},
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
    tableType: "PublicContainers",
    }),
    watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  computed: {
    currentWeek() {
      let currentWeek = moment().isoWeek();
      let currentYear = moment().isoWeekYear();
      return `${currentYear} - W${currentWeek}`
    },
    dateText() {
      if (this.params.dates.length === 0) {
        return "Select Dates";
      } else if (this.params.dates.length === 1) {
        return "Week: " + moment(this.params.dates[0]).isoWeek();
      } else {
        return `${this.params.dates[0]} - ${this.params.dates[1]}`;
      }
    },
  },
  created() {
    if (
      localStorage[
        "publicContainerSearchParams_" + this.$store.state.currentOrg.id
      ]
    ) {
      this.params = JSON.parse(
        localStorage[
          "publicContainerSearchParams_" + this.$store.state.currentOrg.id
        ]
      );
      if (!this.params) {
        this.params = {};
      }
      if (!this.params.dateType) {
        this.params.dateType = "ETD";
      }
      if (
        this.params.selectedDate &&
        this.params.selectedDate != "select_dates"
      ) {
        this.selectDate(this.params.selectedDate);
      }
    } else {
      this.params = {
        dateType: "ETD",
        selectedDate: "current_week",
        dates: [],
      };
      this.selectDate("current_week");
    }
    this.sidebar = {
      toolPanels: [
        // {
        //   id: "templates",
        //   labelDefault: "Templates",
        //   labelKey: "templates",
        //   iconKey: "colorPicker",
        //   toolPanel: "Templates",
        //   minWidth: 300,
        //   maxWidth: 500,
        //   width: 300,
        //   toolPanelParams: {
        //     tableType: this.tableType,
        //   },
        // },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          maxWidth: 225,
          width: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
      defaultToolPanel: "ActionPanel",
    };
    this.columns = [
      {
        headerName: "Container No.",
        field: "containerNo",
      },
      {
        headerName: "Seal No.",
        field: "sealNo",
      },

      {
        headerName: "Bill of Lading No.",
        field: "billOfLadingNo",
      },
      {
        field: "customerContainerRef",
        headerName: "Customer Ref.",
      },

      {
        field: "shipmentFile.fileNumber",
        headerName: "File",
        cellRenderer: "FileStatus",
        cellRendererParams: (p) => {
          return p.data && p.data.shipmentFile
            ? {
                status: p.data.shipmentFile.status,
                fileNumber: p.data.shipmentFile.fileNumber,
              }
            : p.value;
        },
        },
        {
        headerName: "Status",
        field: "formattedStatus",
        cellRenderer: "ContainerStatus",
        cellStyle: { textAlign: "center" },
      },
      {
        headerName: "Transport Status",
        field: "transportStatus",
        cellRenderer: 'TransportStatus',
        cellStyle: { textAlign: "center"},
      },
      {
        headerName: "Booking Status",
        field: "booking.state",
        cellRenderer: "BookingState",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Order No.",
        field: "booking.orderNo",
      },
      {
        field: "ctoNo",
        headerName: "CTO No.",
      },

      {
        headerName: "Type",
        field: "containerTypeCode",
      },
      {
        headerName: "Nett Weight",
        field: "nettWeight",
      },
      {
        headerName: "Gross Weight",
        field: "grossWeight",
      },
      {
        headerName: "Tare Weight",
        field: "tareWeight",
      },
      {
        headerName: "VGM Weight",
        field: "vgmWeight",
      },
      {
        headerName: "Pallets",
        field: "totalPallets",
      },
      {
        headerName: "Shipper",
        field: "shipper.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data  && p.data.shipper
            ? {
                organisation: p.data.shipper,
              }
            : null;
        },
      },
      {
        headerName: "Forwarder",
        field: "forwarder.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data  && p.data.forwarder
            ? {
                organisation: p.data.forwarder,
              }
            : null;
        },
      },

      {
        headerName: "Consignee",
        field: "consignee.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data  && p.data.consignee
            ? {
                organisation: p.data.consignee,
              }
            : null;
        },
      },

      {
        headerName: "Buyer",
        field: "buyer.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data  && p.data.buyer
            ? {
                organisation: p.data.buyer,
              }
            : null;
        },
      },

      {
        headerName: "Carrier",
        field: "shippingLine.name",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p.data  && p.data.shippingLine
            ? {
                organisation: p.data.shippingLine,
              }
            : null;
        },
      },
      {
        headerName: "Carrier Ref",
        field: "booking.carrierReferenceNumber",
      },
      {
        headerName: "Transport Coordinator",
        field: "booking.transportCoordinator.name",
        cellRenderer: "OrganisationName",
        valueGetter: (p) => {
          return p.data  && p.data.booking
            ? p.data.isCarrierHaulage
              ? p.data.shippingLine
                ? p.data.shippingLine.name
                : "Carrier Haulage"
              : p.data.isPrivateHaulage && p.data.transportCoordinator
              ? p.data.transportCoordinator.name
              : p.data.isRailage
              ? "Rail Transport"
              : null
            : null;
        },
        cellRendererParams: (p) => {
          return p.data  && p.data.booking
            ? p.data.isCarrierHaulage
              ? {
                  organisation:
                    p.data.shippingLine ??
                    p.data.shippingLine,
                }
              : p.data.isPrivateHaulage && p.data.transportCoordinator
              ? {
                  organisation: p.data.transportCoordinator,
                }
              : p.data.isRailage
              ? { organisation: { name: "Rail Transport" } }
              : null
            : null;
        },
      },
      {
        headerName: "Vessel/Voyage",
        field: "vesselVoyage",
      },
      {
        headerName: "POL",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return p.data  && p.data.portOfLoadValue
            ? {
                locode: p.data.portOfLoadValue,
                name: p.data.portOfLoadCity,
              }
            : null;
        },
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return p.data &&
            p.data.shipment &&
            p.data.portOfDischargeValue
            ? {
                locode: p.data.portOfDischargeValue,
                name: p.data.portOfDischargeCity,
              }
            : null;
        },
      },

      {
        headerName: "Destination",
        field: "finalDestinationValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return p.data &&
            p.data.shipment &&
            p.data.finalDestinationValue
            ? {
                locode: p.data.finalDestinationValue,
                name: p.data.finalDestinationCity,
              }
            : null;
        },
      },
      {
        headerName: "Method",
        field: "verificationMethod",
      },

      {
        headerName: "Regime Code",
        field: "booking.regimeCode",
      },
      {
        headerName: "Features",
        field: "containerFeatures",
        filterable: false,
        cellRenderer: "Features",
        cellStyle: { textAlign: "center", justifyContent: "center" },
      },
      {
        headerName: "Departure Week",
        field: "sailingWeekText",
      },
      {
        headerName: "ETD",
        field: "etd",
      },
      {
        headerName: "ATD",
        field: "atd",
      },
      {
        headerName: "Arrival Week",
        field: "arrivalWeekText",
      },
      {
        headerName: "ETA",
        field: "eta",
      },
      {
        headerName: "ATA",
        field: "ata",
        },
      {
          headerName: "Stuff Date",
            field: "stuffDate"
        },
      {
        headerName: "Set Point Temp(°C)",
        field: "setPointTemp"
      },
      {
        headerName: "Products",
        field: "products",
      },
    ];
  },
  methods: {
    changeDateType() {
      if (this.params.dateType == "ETD") {
        this.params.dateType = "ETA";
      } else {
        this.params.dateType = "ETD";
      }
      localStorage.setItem(
        "publicContainerSearchParams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
      this.dateKey++;
      this.getContainers();
    },
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
      localStorage.setItem(
        `filterModel-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`,
        JSON.stringify(this.gridApi.getFilterModel())
      );
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
        {
            name: "View Container",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if(params.node && params.node.data){
                this.viewItem('CONTAINER', params.node.data.containerNo ?? params.node.data.ctoNo, params.node.data.id)
              }
            }
          },
          {
            name: "View File",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if(params.node && params.node.data && params.node.data.shipment){
                this.viewItem('FILE', params.node.data.shipmentFile.fileNumber, params.node.data.shipment.shipmentReference)
              }
            }
          },
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    async getContainers() {
      if (this.containerTimeout) {
        clearTimeout(this.containerTimeout);
      }
      if (this.loading && this.containerQuery) {
        this.containerQuery.abort();
      }
      this.loading = true;
      let params = { ...this.params };
      if (this.params.dates.length == 1) {
        params.dates = [
          moment(this.params.dates[0])
            .startOf("isoWeek")
            .toISOString()
            .substring(0, 10),
          moment(this.params.dates[0])
            .endOf("isoWeek")
            .toISOString()
            .substring(0, 10),
        ];
      }
      this.containerTimeout = setTimeout(async () => {
        this.containerQuery = new AbortController();
        const signal = this.containerQuery.signal;
        this.containers = await this.$API.getPublicContainers({
          params: params,
          signal,
        });
        this.loading = false;
      }, 500);
      // let res = await this.$API.getPublicContainers(this.params);
      // console.log("res", res);
    },
    getRowId(params) {
      return params.data.id;
    },
    async load() {
      this.getContainers();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.load();
    },
    async resetFilters() {
      this.gridApi.setFilterModel(null);
      localStorage.setItem(
        `filterModel-${this.tableType}-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`,
        JSON.stringify(this.gridApi.getFilterModel())
      );
    },
    setDateRange(val) {
      switch (val) {
        case "current_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 30))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_week":
          this.params.dates = [
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
            )
              .toISOString()
              .substring(0, 10),
            new Date(
              new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
            )
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "current_month":
          this.params.dates = [
            new Date(new Date().setDate(1)).toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "past_day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_3day":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 3))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "past_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() - 7))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "today":
          this.params.dates = [
            new Date().toISOString().substring(0, 10),
            new Date().toISOString().substring(0, 10),
          ];
          break;
        case "tomorrow":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 7))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "2_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 14))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "6_week":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 42))
              .toISOString()
              .substring(0, 10),
          ];
          break;
        case "next_month":
          this.params.dates = [
            new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .substring(0, 10),
            new Date(new Date().setDate(new Date().getDate() + 30))
              .toISOString()
              .substring(0, 10),
          ];
          break;
      }
      this.getContainers();
    },
    selectDate(value) {
      this.params.selectedDate = value;
      this.setDateRange(value);
      localStorage.setItem(
        "publicContainerSearchParams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
    },
    setDateFilter() {
      if (this.params.dates.length == 2) {
        if (this.params.dates[1] < this.params.dates[0]) {
          let dates = [this.params.dates[1], this.params.dates[0]];
          this.params.dates = dates;
        }
      }
      localStorage.setItem(
        "publicContainerSearchParams_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.params)
      );
      this.dateMenu = false;
      this.getContainers();
    },
    viewItem(type, reference, id) {
      this.$router.push(`/public/shipments/view?id=${id}&type=${type}&reference=${reference}`)
    }
  },
};
</script>